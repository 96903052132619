// extracted by mini-css-extract-plugin
export var aArrow1 = "oc";
export var aArrow2 = "pc";
export var aButton1 = "ec";
export var aButton2 = "fc";
export var aButton3 = "gc";
export var aButton4 = "hc";
export var aButton5 = "ic";
export var aButtonHeaderLogin = "jc";
export var aButtonHeaderRegister = "kc";
export var aButtonHeaderSpLogin = "nc";
export var aButtonLogin = "mc";
export var aButtonRegister = "lc";
export var aDate = "dc";
export var aEmpty = "_b";
export var aInner = "cc";
export var aText = "qc";
export var aTitle = "ac";
export var animationMarquee = "rc";
export var pNewsList = "bc";