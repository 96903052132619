// extracted by mini-css-extract-plugin
export var aArrow1 = "Tb";
export var aArrow2 = "Ub";
export var aButton1 = "Db";
export var aButton2 = "Eb";
export var aButton3 = "Fb";
export var aButton4 = "Gb";
export var aButton5 = "Hb";
export var aButtonHeaderLogin = "Ib";
export var aButtonHeaderRegister = "Jb";
export var aButtonHeaderSpLogin = "Mb";
export var aButtonLogin = "Lb";
export var aButtonRegister = "Kb";
export var aLabel = "Xb";
export var aSelect = "Yb";
export var aTagAnnounce = "Qb";
export var aTagClose = "Pb";
export var aTagEntry = "Nb";
export var aTagFree = "Sb";
export var aTagLottery = "Rb";
export var aTagPayment = "Ob";
export var animationMarquee = "Zb";
export var pListController = "Vb";
export var pListController__inner = "Wb";