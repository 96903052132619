// extracted by mini-css-extract-plugin
export var aArrow1 = "yb";
export var aArrow2 = "zb";
export var aButton1 = "ib";
export var aButton2 = "jb";
export var aButton3 = "kb";
export var aButton4 = "lb";
export var aButton5 = "mb";
export var aButtonHeaderLogin = "nb";
export var aButtonHeaderRegister = "ob";
export var aButtonHeaderSpLogin = "rb";
export var aButtonLogin = "qb";
export var aButtonRegister = "pb";
export var aEmpty = "db";
export var aInner = "gb";
export var aMarquee = "hb";
export var aTagAnnounce = "vb";
export var aTagClose = "ub";
export var aTagEntry = "sb";
export var aTagFree = "xb";
export var aTagLottery = "wb";
export var aTagPayment = "tb";
export var aText = "Ab";
export var aTitle = "eb";
export var animationMaquee = "Bb";
export var animationMarquee = "Cb";
export var pMarqueePageTitle = "fb";