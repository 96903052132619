// extracted by mini-css-extract-plugin
export var aArrow1 = "Na";
export var aArrow2 = "Oa";
export var aButton1 = "Da";
export var aButton2 = "Ea";
export var aButton3 = "Fa";
export var aButton4 = "Ga";
export var aButton5 = "Ha";
export var aButtonHeaderLogin = "Ia";
export var aButtonHeaderRegister = "Ja";
export var aButtonHeaderSpLogin = "Ma";
export var aButtonLogin = "La";
export var aButtonRegister = "Ka";
export var aButtons = "Ya";
export var aCategory = "Ba";
export var aCategoryGray = "Aa";
export var aDate = "Ca";
export var aDetail = "va";
export var aFullImage = "Xa";
export var aLabel = "wa";
export var aLink = "ya";
export var aTags = "Za";
export var aText = "Ra";
export var aTitle = "ta";
export var aTitle1 = "Wa";
export var animationMarquee = "cb";
export var lSection = "Pa";
export var lSection__column2 = "Ta";
export var lSection__column2Border = "Sa";
export var lSection__content = "Ua";
export var lSection__contentNoBorder = "Va";
export var lSection__figureBox = "bb";
export var lSection__header = "ua";
export var lSection__headerNoborder = "sa";
export var lSection__iframe = "ab";
export var lSection__iframeBorder = "_a";
export var lSection__inner = "Qa";
export var lSection__more = "za";
export var lSection__moreNoborder = "xa";