// extracted by mini-css-extract-plugin
export var aArrow1 = "ed";
export var aArrow2 = "fd";
export var aButton1 = "Pc";
export var aButton2 = "Qc";
export var aButton3 = "Rc";
export var aButton4 = "Sc";
export var aButton5 = "Tc";
export var aButtonHeaderLogin = "Uc";
export var aButtonHeaderRegister = "Vc";
export var aButtonHeaderSpLogin = "Yc";
export var aButtonLogin = "Xc";
export var aButtonRegister = "Wc";
export var aEmpty = "Mc";
export var aTagAnnounce = "bd";
export var aTagClose = "ad";
export var aTagEntry = "Zc";
export var aTagFree = "dd";
export var aTagLottery = "cd";
export var aTagPayment = "_c";
export var aText = "gd";
export var aTitle = "Nc";
export var animationMarquee = "hd";
export var pProductsList = "Oc";