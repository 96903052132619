// extracted by mini-css-extract-plugin
export var aArrow1 = "kc";
export var aArrow2 = "lc";
export var aButton1 = "Vb";
export var aButton2 = "Wb";
export var aButton3 = "Xb";
export var aButton4 = "Yb";
export var aButton5 = "Zb";
export var aButtonHeaderLogin = "_b";
export var aButtonHeaderRegister = "ac";
export var aButtonHeaderSpLogin = "dc";
export var aButtonLogin = "cc";
export var aButtonRegister = "bc";
export var aEmpty = "Qb";
export var aInner = "Tb";
export var aMarquee = "Ub";
export var aTagAnnounce = "hc";
export var aTagClose = "gc";
export var aTagEntry = "ec";
export var aTagFree = "jc";
export var aTagLottery = "ic";
export var aTagPayment = "fc";
export var aText = "mc";
export var aTitle = "Rb";
export var animationMaquee = "nc";
export var animationMarquee = "oc";
export var pMarqueePageTitle = "Sb";