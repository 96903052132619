// extracted by mini-css-extract-plugin
export var aArrow1 = "Gb";
export var aArrow2 = "Hb";
export var aEmpty = "wb";
export var aLeft = "Lb";
export var aRight = "Nb";
export var aTagAnnounce = "Db";
export var aTagClose = "Cb";
export var aTagEntry = "Ab";
export var aTagFree = "Fb";
export var aTagLottery = "Eb";
export var aTagPayment = "Bb";
export var aText = "Ib";
export var aTitle = "xb";
export var aWrap = "Kb";
export var animationMarquee = "Pb";
export var infinityScrollLeft = "Mb";
export var infinityScrollRight = "Ob";
export var pItemBatch = "yb";
export var pItemBatchAbs = "zb";
export var pSlideItemList = "Jb";